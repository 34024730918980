.dashboardLayout {
      display: flex;
      gap: 50px;
      padding-top: 20px;
      height: 100%;
      flex-direction: column;

      @media screen and (min-width: 768px) {
            flex-direction: row;
      }

      .menu {
            flex: 1;
            width: 100%;
      }

      .content {
            flex: 4;
            width: 100%;
      }

      @media screen and (max-width: 767px) {
            gap: 20px;
            padding-top: 10px;

            .menu {
                  order: 2;
            }

            .content {
                  order: 1;
            }
      }

      .menu {
            flex: 1;
      }

      .content {
            flex: 4;
            background-color: #bbb4d2;
      }
}