.chatList {
      width: 250px;
      height: 100vh;
      background-color: #f0f4f8;
      padding: 20px;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      /* Add this to make the entire component scrollable if needed */
}

.title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 15px;
}

.dashboard-links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
}

.dashboard-links a {
      text-decoration: none;
      color: #4a5568;
      font-size: 14px;
      transition: color 0.3s ease;
}

.dashboard-links a:hover {
      color: #2b6cb0;
}

hr {
      border: none;
      border-top: 1px solid #e2e8f0;
      margin: 15px 0;
}

.list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      overflow-y: auto;
      flex-grow: 1;
      min-height: 0;
      /* Add this to allow the list to shrink */
}

.list a {
      text-decoration: none;
      color: #4a5568;
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      transition: background-color 0.3s ease;
}

.list a:hover {
      background-color: #e2e8f0;
}

.upgrade {
      background-color: #ebf8ff;
      border-radius: 8px;
      padding: 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: auto;
      /* Push the upgrade section to the bottom */
}

.upgrade img {
      width: 40px;
      height: 40px;
}

.upgrade-text h3 {
      font-size: 16px;
      color: #2b6cb0;
      margin: 0 0 5px 0;
}

.upgrade-text p {
      font-size: 12px;
      color: #4a5568;
      margin: 0;
}