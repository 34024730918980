.dashboardPage {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;

      .texts {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            gap: 50px;

            .logo {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  opacity: 0.2;

                  img {
                        width: 64px;
                        height: 64px;
                  }

                  h1 {
                        font-size: 64px;
                        background: linear-gradient(to right, #217bfe, #e55571);
                        background-clip: text;
                        -webkit-background-clip: text;
                        color: transparent;
                  }
            }

            .options {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 50px;
                  color: #630f0f;

                  .option {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        font-weight: 300;
                        font-size: 14px;
                        padding: 20px;
                        border: 1px solid #630f0f;
                        border-radius: 20px;

                        img {
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                        }
                  }
            }
      }

      .formContainer {
            margin-top: auto;
            width: 50%;
            background-color: #2c2937;
            border-radius: 20px;
            display: flex;

            form {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 20px;
                  margin-bottom: 10px;

                  input {
                        flex: 1;
                        padding: 20px;
                        background-color: transparent;
                        border: none;
                        outline: none;
                        color: #ececec;
                  }

                  button {
                        background-color: #605e68;
                        border-radius: 50%;
                        border: none;
                        cursor: pointer;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 20px;

                        img {
                              width: 16px;
                              height: 16px;
                        }
                  }
            }
      }
}