.chatPage {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: white;

      .wrapper {
            flex: 1;
            overflow: scroll;
            width: 100%;
            display: flex;
            justify-content: center;

            .chat {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p,
                  li {
                        margin: 10px 0px;
                  }

                  .message {
                        padding: 20px;
                        color: black;

                        &.user {
                              background-color: #b9ddcb;
                              border-radius: 20px;
                              max-width: 80%;
                              align-self: flex-end;
                        }
                  }
            }
      }
}