.dashboardLayout {
      display: flex;
      gap: 100px;
      padding-top: 100px;
      height: 100%;

      
      .content{
            background-color: #858b8c;
            color: rgb(236, 236, 236);
      }

}

