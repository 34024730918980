.home-page {
    min-height: 100vh;
    background-color: #f4f4f4;
    color: #333333;
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

.brand-link {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.icon {
    margin-right: 8px;
}
.brand-name {
    color: #4285F4;
}
  
.menu-icon {
    display: none;
}

.nav-links {
    display: flex;
    gap: 16px;
}

.drawer .MuiDrawer-paper {
    background-color: #f4f4f4;
}

.hero-section {
    background-color: #0a74da;
    color: #ffffff;
    padding: 60px 20px;
}

.hero-container {
    text-align: center;
}

.badge {
    display: inline-block;
    background-color: #4caf50;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 16px;
}

.hero-title {
    font-weight: bold;
    margin-bottom: 16px;
}

.hero-description {
    margin-bottom: 24px;
    font-size: 1.1rem;
}

.hero-buttons button {
    margin: 8px;
}
.hero-buttons {
    text-align: center; /* Ensures the buttons stay centered */
  }
  
.featured-section {
    padding: 60px 20px;
    text-align: center;
}

.section-title {
    font-weight: bold;
    margin-bottom: 16px;
}

.section-description {
    margin-bottom: 24px;
    color: #666666;
}

.product-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.product-icon {
    font-size: 40px;
    color: #0a74da;
    margin-bottom: 8px;
}

.footer {
    background-color: #333333;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-text {
    margin: 0;
}

.footer-links {
    display: flex;
    gap: 16px;
}

.footer-link {
    color: #bbbbbb;
    text-decoration: none;
}

.footer-link:hover {
    color: #ffffff;
}

/* Responsive styling */
@media (max-width: 768px) {
    .menu-icon {
        display: inline-block;
    }

    .nav-links {
        display: none;
    }

    .hero-title {
        font-size: 2rem;
    }

    .hero-description {
        font-size: 1rem;
    }

    .product-card {
        width: 100%;
        max-width: 280px;
        margin: 10px auto;
    }
}
