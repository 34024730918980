

.exploreheader {
      padding: 0;
            margin: 0;
            box-sizing: border-box;
      background-color: #0e0c16;
      color: #ececec;
      min-height: 100vh;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
      /* Ensures the black background covers the full viewport height */
}
